<template>
  <div class="content-box">
    <div class="vs-loading vs-loading--default vs-loading--target" style="--vs-color: 25, 91, 255;">
      <div class="vs-loading__load">
        <div class="vs-loading__load__animation">
          <div class="vs-loading__load__percent"></div>
          <div class="vs-loading__load__animation__1"></div>
          <div class="vs-loading__load__animation__2"></div>
          <div class="vs-loading__load__animation__3"></div>
        </div>
        <div class="vs-loading__load__text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
// .content-box{
//   min-height: 100px;
// }
</style>
