<template>
    <div class="section-form-content">
        <div class="lfont" v-if="recruitment_data">
            <ShowRecruitmentDetail :recruitment_data="recruitment_data" />
            <br>
            <v-btn class="mx-2 lfont text-white" color="warning" @click="edit_data">
                <v-icon dark>mdi-pencil</v-icon> ແກ້ໄຂ
            </v-btn>
        </div>
        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import ShowRecruitmentDetail from "@/components/Recruitments/ShowRecruitmentDetail.vue";
export default {
    components: {
        Loading,
        ShowRecruitmentDetail,
    },
    data() {
        return {
            request_id: this.$route.params.id,
            isLoading: false,
            recruitment_data: false,
        }
    },
    methods: {
        edit_data() {
            this.$router
                .push({
                    name: "recruitment.edit",
                    params: {
                        id: this.$route.params.id,
                    },
                })
                .catch(() => { });
        },
        fetchRecruitment() {
            this.isLoading = true;
            this.$axios.get(`/employee/recruitment/request/${this.request_id}`).then((res) => {
                if (res.status === 200) {
                    this.recruitment_data = res.data.data;
                }
                this.isLoading = false;
            });
        }
    },
    created() {
        this.fetchRecruitment();
    },
}
</script>

<style lang="scss" scoped>
.table {
    td {
        border: 1px solid white !important;
    }
}
</style>