<template>
    <div>
        <table class="table">
            <tr>
                <td colspan="2">
                    <h5 class="text-primary">ຕຳແໜ່ງທີ່ຕ້ອງການ</h5>
                </td>
            </tr>
            <tr>
                <td><b>ຊື້ຕຳແໜ່ງ:</b></td>
                <td>{{ recruitment_data.position.name }}</td>
            </tr>
            <tr>
                <td><b>ວັນທີ່ຕ້ອງການ:</b></td>
                <td>{{ recruitment_data.need_date }}</td>
            </tr>
            <tr>
                <td><b>ຈຳນວນຕຳແໜ່ງ:</b></td>
                <td>{{ recruitment_data.need_qty }}</td>
            </tr>
            <tr>
                <td><b>ພະແນກ/ໜ່ວຍງານ:</b></td>
                <td>{{ recruitment_data.working_at_department.name }}</td>
            </tr>
            <tr>
                <td><b>ສະຖານທີ່ເຮັດວຽກ:</b></td>
                <td>{{ recruitment_data.working_place }}</td>
            </tr>


            <tr>
                <td colspan="2">
                    <h5 class="text-primary">ລາຍລະອຽດຕຳແໜ່ງ</h5>
                </td>
            </tr>
            <tr>
                <td><b>ຕຳ​ແໜ່ງ​ດັ່ງ​ກ່າວນີ້​ແມ່ນ:</b></td>
                <td>{{ recruitment_data.reason.name }}</td>
            </tr>
            <tr>
                <td><b>ສັນຊາດ:</b></td>
                <td>{{ recruitment_data.nationality }}</td>
            </tr>
            <tr>
                <td><b>ຮູບແບບສັນຍາ:</b></td>
                <td>{{ recruitment_data.contract_type.name }}</td>
            </tr>
            <tr>
                <td><b>ໜ້າທີ່ຮັບພິຊອບ:</b></td>
                <td>{{ recruitment_data.responsibilities }}</td>
            </tr>
            <tr>
                <td><b>ຄຸນນະສົມບັດ:</b></td>
                <td>
                    <ol style="padding: 0; margin: 0px;">
                        <li v-for="item in recruitment_data.properties">{{ item.name }}</li>
                    </ol>
                </td>
            </tr>
            <tr>
                <td><b>ອະນຸມັດໂດຍ:</b></td>
                <td>
                    <ul style="padding: 0; margin: 0px;">
                        <li v-for="item in recruitment_data.approver">{{ item.name }} ({{
                            $t(`recruitment.${item.position}`) }})</li>
                    </ul>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        recruitment_data: {}
    }

}
</script>

<style lang="scss" scoped></style>